<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.path" />
    </keep-alive>
  </div>
</template>

<script>
import '@/css/main.scss'
import { mapGetters } from 'vuex';

export default {
  name: "App",
  computed: {
    ...mapGetters([
      'host',
      "domainConfig",
    ])
  },
  created () {
    let debug = this.$global.GetQueryString('db')
    if (debug) {
      this.$store.commit('setDebug', true)
    }
    if (!this.domainConfig) {
      if (!this.host) {
        this.$store.state.host = 'tarotmystic.online'
      }
      this.$store.commit('setDomain', require('@/utils/config/domainConfig.json')[this.host])
      this.$store.commit('setAdsense', require('@/utils/config/domainConfig.json')[this.host].adsense)

      console.log(this.$store.state.domainConfig);
    }
  },
  beforeMount() {
		const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		if (flag) {
			this.$store.state.deviceType = 'mobile'
		} else {
			this.$store.state.deviceType = 'desktop'
		}
		console.log(this.$store.state.deviceType)
	},
};
</script>
